<template>
  <div
    id="home"
    class="m-0 main"
  >
    <div id="particleContainer">
      <ParticlesComp
        part="tsparticles-f-blur-bright"
      />
      <ParticlesComp
        part="tsparticles-f"
      />
      <ParticlesComp
        part="tsparticles-m"
      />
      <ParticlesComp
        part="tsparticles-b"
      />
    </div>
    <div
      class="w-100 justify-content-center"
    >
      <img
        class="homeHead"
        src="../static/head_500x500.png"
      >
    </div>
    <div
      class="row w-100 d-flex justify-content-center text-center h-50"
      align-h="center"
    >
      <div class="">
        <img
          class="homeLogo  fluid d-none d-md-block d-lg-block d-xl-block"
          src="../static/logo_2000x621.png"
        >
      </div>
      <img
        class="homeLogo fluid d-block d-md-none d-lg-none d-xl-none"
        src="../static/logo_vert_664x1000.png"
      >
    </div>
  </div>
</template>

<script>
import scroll from '@/directives/scroll';
import ParticlesComp from '@/components/ParticlesComponent.vue';

export default {
  directives: {
    scroll,
  },
  components: {
    ParticlesComp,
  },
  methods: {
    handleScroll(evt, el) {
      if (window.scrollY > 50) {
        el.setAttribute(
          'style',
          'width: 10px',
        );
      }
      return window.scrollY > 100;
    },
  },
};
</script>
<style scoped>
#home {
  display: flex;
  text-align: center;
  transition: 1.5s all;
  background: rgba(8, 15, 3, 0);
}
#home div {
  max-height: 100vh
}
#particleContainer{
  background: var(--c-bg-green-dark);
  z-index: -10;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  }
.homeHead {
  height: 48vh;
  width: auto;
  z-index: 5;
  margin-top: 2vh;
}
.homeLogo {
  max-width: 100%;
  max-height: 50vh;
  vertical-align: center;
  margin: auto 0;
  z-index: 5;
}
@media (orientation: portrait){
  .homeHead {
    height: auto;
    width: 50vw;
  }
  .homeLogo {
  width: 50vw;
  height: auto;
  vertical-align: center;
  }
}

</style>
