<template>
  <div class="showreel">
    <div class="head d-flex justify-content-center">
      <H1>Singles</H1>
    </div>
    <div class="body">
      <div
        id="single-carousel"
        class="carousel slide w-100"
        data-ride="0"
        data-interval="0"
      >
        <div class="d-none d-lg-block">
          <ol
            v-if="singles.length>1"
            class="carousel-indicators"
          >
            <li
              v-for="single in singles"
              :key="single.id"
              data-target="#single-carousel"
              data-slide-to="single.id"
              :class="{active: single.id === show_single}"
            />
          </ol>
        </div>
        <div
          class="carousel-inner"
          role="listbox"
        >
          <div
            v-for="single in singles"
            :key="single.key"
            class="carousel-item"
            :class="{active: single.id === show_single}"
          >
            <img
              class="bgimg w-100 d-none d-md-block d-landscape"
              :src="single.coverImg"
            >

            <div class="carousel-caption">
              <div class="h-75 imgwrapper">
                <img
                  class="captionimg"
                  :src="single.coverImg"
                >
              </div>
              <div class="listen listen-singles">
                <div
                  v-if="single.spotify"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Single on Spotify"
                  >
                    <a
                      :id="single.name + `_spotify`"
                      target="_blank"
                      href="#"
                      @click="linkClick(single.spotify)"
                    >
                      <i
                        class="icon fab fa-spotify embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="single.amazon"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Single on Amazon Music"
                  >
                    <a
                      :id="single.name + `_amazon`"
                      target="_blank"
                      href="#"
                      @click="linkClick(single.amazon)"
                    >

                      <i
                        class="icon fab fa-amazon embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="single.youtube"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Single on Youtube"
                  >
                    <a
                      :id="single.name + `_youtube`"
                      target="_blank"
                      href="#"
                      @click="linkClick(single.youtube)"
                    >
                      <i
                        class="icon fab fa-youtube embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="single.deezer"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Single on Deezer"
                  >
                    <a
                      :id="single.name + `_deezer`"
                      target="_blank"
                      href="#"
                      @click="linkClick(single.deezer)"
                    >
                      <img
                        src="../static/deezer.svg"
                        class="custom-svg"
                      ></a>
                  </div>
                </div>
                <div
                  v-if="single.itunes"
                  class="streamlink itunesicon d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Single on Apple Music"
                  >
                    <a
                      :id="single.name + `_itunes`"
                      target="_blank"
                      href="#"
                      @click="linkClick(single.itunes)"
                    >
                      <i
                        class="icon fab fa-itunes embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="single.tidal"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Single on Tidal"
                  >
                    <a
                      :id="single.name + `_tidal`"
                      target="_blank"
                      href="#"
                      @click="linkClick(single.tidal)"
                    >
                      <img
                        src="../static/tidal.svg"
                        class="custom-svg"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          v-if="singles.length>1"
          class="carousel-control-prev"
          href="#single-carousel"
          role="button"
          data-slide="prev"
        >
          <span
            class="carousel-control-prev-icon"
            aria-hidden="true"
          />
          <span class="sr-only">Previous</span>
        </a>
        <a
          v-if="singles.length>1"
          class="carousel-control-next"
          href="#single-carousel"
          role="button"
          data-slide="next"
        >
          <span
            class="carousel-control-next-icon"
            aria-hidden="true"
          />
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    singles: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      show_single: 0,
    };
  },
  mounted() {
    this.items = this.singles;
    if (this.$route.query.single && parseInt(this.$route.query.single, 10) < this.singles.length) {
      this.show_single = parseInt(this.$route.query.single, 10);
    } else {
      this.show_single = this.singles.length - 1;
    }
  },
  methods: {
    linkClick(link) {
      window.open(link);
    },
  },
};

</script>
