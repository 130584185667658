<template>
  <div id="social">
    <div class="head d-flex justify-content-center">
      <h1>Social Media Channels</h1>
    </div>
    <div class="body row row-cols-1">
      <div class="col">
        <Card
          class="socialCard"
          header="Follow me on socal media"
        >
          <a
            href="https://www.facebook.com/ChrisFasslMusic/"
            target="_blank"
          >
            <i
              class="fab fa-facebook"
              title="Facebook"
            />
          </a>
          <a
            href="https://discord.gg/NdMEVCYx"
            target="_blank"
          >
            <i
              class="fab fa-discord"
              title="Discord"
            />
          </a>
          <a
            href="https://www.instagram.com/chris_fassl_music/"
            target="_blank"
          >
            <i
              class="fab fa-instagram"
              title="Instragram"
            />
          </a>

          <a
            href="https://www.youtube.com/channel/UC1ZpWO05Wszx0UGW-mf8R8g"
            target="_blank"
          >
            <i
              class="fab fa-youtube"
              title="Youtube"
            />
          </a>
          <a
            href="https://twitter.com/ChrisFasslMusic"
            target="_blank"
          >
            <i
              class="fab fa-twitter"
              title="Twitter"
            />
          </a>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '@/components/Card.vue';

export default {
  components: {
    Card,
  },
  data() {
    return {
      consent: false,
    };
  },
  mounted() {
    this.consent = JSON.parse(localStorage.getItem('cookie:accepted'));
  },
};
</script>
<style  lang="scss" scoped>

.body{
  background-image: url('../static/social_bg_sml.jpg');
    background-size: 100vw auto;
    background-position: center top;
    background-repeat: no-repeat;
}

/* resizing */
@media (max-aspect-ratio: 16/9){
  .body{
    background-size: auto 100vh;
    background-position: center top;
    background-repeat: no-repeat;
  }
}
/* changing bg img*/
@media only screen and (min-width: 1440px) {
  .body{
   background-image: url('../static/social_bg_hd.jpg');
  }
}
@media only screen and (min-width: 1560px) {
  .body{
    background-image: url('../static/social_bg_wqhd.jpg');
  }
}

.card {
  text-align: center;
  margin: 4rem;
    & i{
      font-size: 7vh;
      margin: 1.57vh !important;
    }
}

</style>
