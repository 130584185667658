<template>
  <div
    id="contact"
  >
    <div class="head d-flex justify-content-center">
      <h1>Contact / Legal Stuff</h1>
    </div>
    <div class="body row row-cols-1">
      <div class="col">
        <Card
          class="contactCard"
          header="Contact"
        >
          <a href="mailto:chris@chris-fassl.de">chris@chris-fassl.de</a>
        </Card>
      </div>
      <div class="col text-center">
        <button
          class="btn btn-secondary w-50"
          @click="imprintToggle = true"
        >
          <h5>Impressum [DE]</h5>
        </button>
      </div>
      <div
        class="imprint-wrapper"
        :class="{'imprint-show': imprintToggle,
                 'imprint-hide': !imprintToggle}"
      >
        <div
          class="jumbotron imprint py-4"
        >
          <Imprint
            class="mb-0"
            @close="imprintToggle = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Card from '@/components/Card.vue';
import Imprint from '@/components/Imprint.vue';

export default {
  components: {
    Card,
    Imprint,
  },
  data() {
    return {
      imprintToggle: false,
    };
  },

};
</script>
<style lang="scss" scoped>
.body{
   background-image: url('../static/contact_bg_sml.jpg');
    background-size: 100vw auto;
    background-position: center top;
    background-repeat: no-repeat;
}
/* resizing */
@media (max-aspect-ratio: 16/9){
  .body{
    background-size: auto 100vh;
    background-position: center top;
    background-repeat: no-repeat;
  }
}
/* changing bg img*/
@media only screen and (min-width: 1440px) {
  .body{
    background-image: url('../static/contact_bg_hd.jpg');
  }
}
@media only screen and (min-width: 1560px) {
  .body{
    background-image: url('../static/contact_bg_wqhd.jpg');
  }
}
.card {
  margin-top: 2vh;
}
  .imprint-wrapper{
  position: fixed;
  top: 2vh;
  bottom: 2vh;
  left: 2vw;
  right: 2vw;
  z-index: 99;
  border-radius: 10px;
  overflow: hidden;
  padding: 4rem;
  transition: all 2500 ease-in-out;
  }
  .imprint {
    position: absolute;
    top: 0;
    left: 0;
    bottom:0;
    right: 0;
    margin: 0;
    width: 100%;
    overflow-y: scroll;
    background-color: var(--c-blue);
      }
  .imprint-show{
    visibility: visible;
    opacity: 1;
    transition: visibility 250ms, opacity 250ms  ease-in-out;
  }
  .imprint-hide{
    visibility: hidden;
    opacity: 0;
    transition: visibility 250ms, opacity 250ms  ease-in-out;
  }
</style>
