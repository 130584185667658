<template>
  <div class="card">
    <img
      v-if="cardImg"
      :src="cardImg"
      class="card-img-top"
      :alt="cardImgAlt"
    >
    <div
      v-if="header"
      class="card-header text-center"
    >
      <h3>{{ header }}</h3>
    </div>
    <div class="card-body text-center">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Card',
  props: {
    header: {
      type: String,
      default: '',
    },
    cardImg: {
      type: String,
      default: '',
    },
    cardImgAlt: {
      type: String,
      default: '',
    },
  },
};
</script>
