<template>
  <div
    id="playlist"
    name="playlist-wrapper"
  >
    <div class="head d-flex justify-content-center">
      <h1>My Playlist</h1>
    </div>
    <div class="body w-100">
      <div class="wrapper">
        <img
          class="playlist-image"
          src="../static/fierce_and_formidable.jpg"
        >

        <button
          class="btn btn-primary playlist-button "
          @click="linkClick(playlistLink)"
        >
          <i
            class="icon fab fa-spotify embed-responsive-item mx-1"
          />
          Open in Spotify
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    playlistLink() {
      return 'https://open.spotify.com/playlist/1xhv619aQGh6niyON2VHLi?si=4b1317240fc24707';
    },
  },
  mounted() {

  },
  methods: {
    linkClick(link) {
      window.open(link);
    },
  },

};
</script>
<style lang="scss" scoped>
@import '../assets/styles/mixins.scss';

.body{
    background-image: url('../static/fierce_and_formidable_sml.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    vertical-align: middle;

}
  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .playlist-image{
      height: 50%;
      margin: 10% auto auto auto;
      @include shadow1;
    }

    .playlist-button{
      position: absolute;
      bottom: 25%;
      height: 3rem;
      width: 50%;
      left: calc(25%);
      @include shadow1;

    }

  }
/* resizing */
@media (orientation: landscape) {
  .body{
    background-size: 100vw auto;
  }
}

@media (orientation: portrait) {
  .body{
    background-size: auto 100vh;
  }
}

@media (max-aspect-ratio: 16/9){
  .body{
    background-position: center top;
    background-repeat: no-repeat;
    background-position: center;
  }
}
/* changing bg img*/
@media only screen and (min-width: 1440px) {
  .body{
    background-image: url('../static/fierce_and_formidable_hd.jpg');
  }
}
@media only screen and (min-width: 1560px) {
  .body{
    background-image: url('../static/fierce_and_formidable_wqhd.jpg');
  }
}

</style>
