<template>
  <div id="baseBg">
    <Particles
      v-if="part === 'tsparticles-b'"
      id="tsparticles-b"
      class="particles"
      :options="{
        particles: {
          number: {
            value: 120,
            density: {
              enable: true,
              value_area: 1000,
            },
          },
          color: {
            value: '#284727',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0.06,
              color: '#284727',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.09,
            random: true,
            anim: {
              enable: true,
              speed: 3,
              opacity_min: 0.06,
              sync: false,
            },
          },
          size: {
            value: 60,
            random: false,
            anim: {
              enable: true,
              speed: 4,
              size_min: 55,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 500,
            color: '#ffffff',
            opacity: 0.4,
            width: 2,
          },
          move: {
            enable: true,
            speed: 10,
            direction: 'none',
            random: true,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'bubble',
            },
            onclick: {
              enable: true,
              mode: 'bubble',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5,
              },
            },
            bubble: {
              distance: 231,
              size: 68,
              duration: 0.5,
              opacity: 0.6,
              speed: 1,
            },
            repulse: {
              distance: 56.84540486109416,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }"
    />
    <Particles
      v-if="part === 'tsparticles-m'"
      id="tsparticles-m"
      class="particles"
      :options="{
        particles: {
          number: {
            value: 50,
            density: {
              enable: true,
              value_area: 1000,
            },
          },
          color: {
            value: '#0c1b04',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0.06,
              color: '#0c1b04',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.09,
            random: true,
            anim: {
              enable: false,
              speed: 3,
              opacity_min: 0.03,
              sync: false,
            },
          },
          size: {
            value: 60,
            random: false,
            anim: {
              enable: true,
              speed: 4,
              size_min: 55,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 500,
            color: '#ffffff',
            opacity: 0.4,
            width: 2,
          },
          move: {
            enable: true,
            speed: 10,
            direction: 'none',
            random: true,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: false,
              mode: 'bubble',
            },
            onclick: {
              enable: true,
              mode: 'bubble',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5,
              },
            },
            bubble: {
              distance: 231,
              size: 68,
              duration: 0.5,
              opacity: 0.6,
              speed: 1,
            },
            repulse: {
              distance: 56.84540486109416,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }"
    />
    <Particles
      v-if="part === 'tsparticles-f'"
      id="tsparticles-f"
      class="particles"
      :options="{
        particles: {
          number: {
            value: 50,
            density: {
              enable: true,
              value_area: 1000,
            },
          },
          color: {
            value: '#ffd700',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0.06,
              color: '#ffd700',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.18,
            random: true,
            anim: {
              enable: true,
              speed: 0.01,
              opacity_min: 0.065,
              sync: true,
            },
          },
          size: {
            value: 10,
            random: false,
            anim: {
              enable: true,
              speed: 4,
              size_min: 55,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 1500,
            color: '#ffffff',
            opacity: 0.4,
            width: 2,
          },
          move: {
            enable: true,
            speed: 1,
            direction: 'none',
            random: true,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 1600,
              rotateY: 200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: false,
              mode: 'bubble',
            },
            onclick: {
              enable: false,
              mode: 'bubble',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5,
              },
            },
            bubble: {
              distance: 36.54347455356053,
              size: 33,
              duration: 0.3,
              opacity: 3,
              speed: 3,
            },
            repulse: {
              distance: 56.84540486109416,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }"
    />

    <Particles
      v-if="part === 'tsparticles-f-blur-bright'"
      id="tsparticles-f-blur-bright"
      class="particles"
      :options="{
        particles: {
          number: {
            value: 3,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: '#0c1b04',
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0.1,
              color: '#0c1b04',
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: 'img/github.svg',
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.4,
            random: true,
            anim: {
              enable: false,
              speed: 23,
              opacity_min: 0.3,
              sync: false,
            },
          },
          size: {
            value: 70,
            random: false,
            anim: {
              enable: true,
              speed: 1,
              size_min: 50,
              sync: false,
            },
          },
          line_linked: {
            enable: false,
            distance: 500,
            color: '#ffffff',
            opacity: 0.9,
            width: 2,
          },
          move: {
            enable: true,
            speed: 1,
            direction: 'none',
            random: true,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'bubble',
            },
            onclick: {
              enable: false,
              mode: 'bubble',
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 0.5,
              },
            },
            bubble: {
              distance: 10,
              size: 600,
              duration: 0.5,
              opacity: 0.6,
              speed: 1,
            },
            repulse: {
              distance: 56.84540486109416,
              duration: 0.4,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
      }"
    />
  </div>
</template>
<script>
export default {
  props: {
    part: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.particles{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: .8;
      -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
}
#baseBg{
  background-color: #021C00;
}
#tsparticles-f-blur-bright {
    z-index: 4;
}
#tsparticles-f {
    z-index: 3;
}
#tsparticles-m {
    z-index: 2;
}
#tsparticles-b {
    z-index: 1;
}
</style>
