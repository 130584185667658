<template>
  <div
    id="app"
    data-spy="scroll"
  >
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    >
    <div>
      <Loader v-if="appLoading" />
      <Home
        id="home"
        class="section fullscreen"
      />
      <Albums
       v-if="filteredAlbums.length"
        id="albums"
        class="section"
        :albums="filteredAlbums"
      />
      <Singles
        v-if="filteredSingles.length"
        id="singles"
        class="section"
        :singles="filteredSingles"
      />
      <Playlist
        id="playlist"
        class="section"
      />
      <Social
        id="social"
        class="section"
      />
      <Contact
        id="contact"
        class="section final"
      />
      <!-- <Cookie @accept="isAccepted" /> -->
    </div>
  </div>
</template>

<script>
// VIEWS
import VueAnalytics from 'vue-analytics';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import Home from './views/Home.vue';
import Albums from './views/Albums.vue';
import Singles from './views/Singles.vue';
import Playlist from './views/Playlist.vue';
// import Projects from './views/Projects.vue';
import Contact from './views/Contact.vue';
import Social from './views/Social.vue';

// COMPONENTS
import Loader from './components/components/Loader.vue';
// import Cookie from './components/CookieConsent.vue';
import {singles} from '@/data/singles.json';
import {albums} from '@/data/albums.json';

import moment from 'moment'

export default {
  name: 'ChrisFasslMusic',
  Vue,
  VueAnalytics,
  components: {
    Home,
    Albums,
    Singles,
    // Projects,
    Contact,
    Social,
    // Cookie,
    Playlist,
    Loader,
  },
  metaInfo: {
    meta: [
      { property: 'og:image', content: 'https://chris-fassl.de/img/prev_img.jpg' },
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'The home of Epic Orchestral Folk Music of Chris Fassl' },
      { property: 'og:description', content: 'The home of Epic Orchestral Folk Music of Chris Fassl' },
      { property: 'og:title', content: 'Chris Fassl Music' },
      { property: 'og:site_name', content: 'Chris Fassl Music' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://chris-fassl.de' },
      { name: 'robots', content: 'index,follow' },
    ],
  },
  data: () => ({
    appLoading: true,
  }),
  mounted() {
    const qry = this.$router.currentRoute.path.split('/')[1];
    const element = document.getElementById(qry);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    require.context('./static/', true, /\.jpg$/);
    this.appLoading = false;
  },
  computed: {
    filteredAlbums() {
      return albums
      // return this.filterReleases(albums)
    },
    filteredSingles() {
      return singles
      // return this.filterReleases(singles)
    },
  },
  methods: {
    isAccepted() {
      this.$gtm.enable(true);
      this.$ga.enable();
      this.$ga.page({
        page: '/',
        title: 'Home page',
        location: window.location.href,
      });
    },

    filterReleases(data){
      return data.filter((e) => {
        return new Date(e.release.substr(0,4), e.release.substr(5,2), e.release.substr(8,2)) <= new Date()
      })
    },
  },
};

</script>
