<template>
  <div class="showreel">
    <div class="head d-flex justify-content-center">
      <H1>Albums</H1>
    </div>
    <div class="body">
      <div
        id="album-carousel"
        class="carousel slide w-100"
        data-ride="0"
        data-interval="0"
      >
        <div class="d-none d-lg-block">
          <ol
            v-if="albums.length>1"
            class="carousel-indicators"
          >
            <li
              v-for="album in albums"
              :key="album.id"
              data-target="#album-carousel"
              data-slide-to="album.id"
              :class="{active: album.id === show_album}"
            />
          </ol>
        </div>
        <div
          class="carousel-inner"
          role="listbox"
        >
          <div
            v-for="album in albums"
            :key="album.key"
            class="carousel-item"
            :class="{active: album.id === show_album}"
          >
            <img
              class="bgimg w-100 d-none d-md-block d-landscape"
              :src="album.coverImg"
            >

            <div
              class="carousel-caption"
            >
              <div class="h-75 imgwrapper">
                <img
                  :id="album.name"
                  class="captionimg"
                  :src="album.coverImg"
                >
              </div>
              <div
                class="listen"
              >
                <!-- :class=" {listenIn: album.visibility,
                          listenOut: !album.visibility}" -->
                <div
                  v-if="album.spotify"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Album on Spotify"
                  >
                    <a
                      :id="album.name + `_spotify`"
                      target="_blank"
                      href="#"
                      @click="linkClick(album.spotify)"
                    >
                      <i
                        class="icon fab fa-spotify embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="album.amazon"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Album on Amazon Music"
                  >
                    <a
                      :id="album.name + `_amazon`"
                      target="_blank"
                      href="#"
                      @click="linkClick(album.amazon)"
                    >

                      <i
                        class="icon fab fa-amazon embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="album.youtube"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Album on Youtube"
                  >
                    <a
                      :id="album.name + `_youtube`"
                      target="_blank"
                      href="#"
                      @click="linkClick(album.youtube)"
                    >
                      <i
                        class="icon fab fa-youtube embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="album.deezer"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Album on Deezer"
                  >
                    <a
                      :id="album.name + `_deezer`"
                      target="_blank"
                      href="#"
                      @click="linkClick(album.deezer)"
                    >
                      <img
                        src="../static/deezer.svg"
                        class="custom-svg"
                      >
                    </a>
                  </div>
                </div>
                <div
                  v-if="album.itunes"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Album on Apple Music"
                  >
                    <a
                      :id="album.name + `_itunes`"
                      target="_blank"
                      href="#"
                      @click="linkClick(album.itunes)"
                    >
                      <i
                        class="icon fab fa-itunes embed-responsive-item"
                      />
                    </a>
                  </div>
                </div>
                <div
                  v-if="album.tidal"
                  class="streamlink d-flex justify-content-center"
                >
                  <div
                    class="linkwrap embed-responsive embed-responsive-1by1"
                    title="Album on Tidal"
                  >
                    <a
                      :id="album.name + `_tidal`"
                      target="_blank"
                      href="#"
                      @click="linkClick(album.tidal)"
                    >
                      <img
                        src="../static/tidal.svg"
                        class="custom-svg"
                      >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="carousel-control-prev"
          href="#album-carousel"
          role="button"
          data-slide="prev"
        >
          <span
            class="carousel-control-prev-icon"
            aria-hidden="true"
          />
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next"
          href="#album-carousel"
          role="button"
          data-slide="next"
        >
          <span
            class="carousel-control-next-icon"
            aria-hidden="true"
          />
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    albums: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      show_album: 0,
    };
  },
  mounted() {
    this.items = this.albums;
    if (this.$route.query.album && parseInt(this.$route.query.album, 10) < this.albums.length) {
      this.show_album = parseInt(this.$route.query.album, 10);
    } else {
      this.show_album = this.albums.length - 1;
    }
  },
  methods: {

    linkClick(link) {
      window.open(link);
    },

    // visibilityChanged(isVisible, entry) {
    //   console.log(entry.target.id, isVisible);
    //   console.log(this.albums.find((e) => e.name === entry.target.id).name,
    //     this.albums.find((e) => e.name === entry.target.id).visibility);
    //   // console.log(entry.target.id);
    //   // console.log(isVisible);
    //   // console.log(entry);
    //   this.albums.find((e) => e.name === entry.target.id).visibility = isVisible;
    // },

  },
};
</script>
<style>
.listenOut{
  left:-100vw;
   transition: all .5s ease-in-out;
}
.listenIn{
  left:0;
   transition: all .5s ease-in-out;
}

/* .fade-enter-active,
.fade-leave-active {
  transition: all 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
} */
</style>
